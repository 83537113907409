import axios from 'axios'
import authHeader from './auth-header'
import Config from '../../config'

const serverAddress = `${Config.apiServers.url}`

class CustomerService {
  // eslint-disable-next-line class-methods-use-this
  getAll(payload) {
    // eslint-disable-next-line no-underscore-dangle
    const promise = axios.post(`${serverAddress}/admin/customer/list`, payload, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  getById(payload) {
    const promise = axios.post(`${serverAddress}/admin/customer/byId`, { id: payload.id }, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  addManager(payload) {
    const promise = axios.post(`${serverAddress}/admin/customer/addManager`, payload, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  removeManager(payload) {
    const promise = axios.post(`${serverAddress}/admin/customer/removeManager`, payload, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  addAgent(payload) {
    const promise = axios.post(`${serverAddress}/admin/customer/addAgent`, payload, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  addUser(payload) {
    const promise = axios.post(`${serverAddress}/admin/customer/addUser`, payload, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  removeAgent(payload) {
    const promise = axios.post(`${serverAddress}/admin/customer/removeAgent`, payload, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  edit(payload) {
    const promise = axios.post(`${serverAddress}/admin/customer/edit`, payload, { headers: authHeader() })
    return promise.then(response => response.data)
  }

  // eslint-disable-next-line class-methods-use-this
  delete(data) {
    const promise = axios.post(`${serverAddress}/admin/customer/removeUser`,
      {
        data: {
          _id: data.userId,
          companyId: data.companyId,
        },
        headers: authHeader(),
      })
    return promise.then(response => response.data)
  }
}

export default new CustomerService()
