<template>
  <div
    id="home"
    style="z-index: 99;margin-top: -110px;"
  >
      <WinnerPicker />

  </div>

</template>

<script>
import WinnerPicker from '@/components/WinnerPicker.vue'

export default {
  components: {
    WinnerPicker,
  },
  data() {
    return {
    }
  },
}
</script>

<style>
[dir=rtl] * {
  font-family: Vazir;
}

.nodiplay .content-header{
  display: none !important;
}
.nodiplay .content-body{
  margin: -47px -27px !important;
}

#home .deviceRow {
  margin-bottom: 4px;
  width: 100%;
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 7px;
}
.deviceInfo .card-body {
  padding-top: 8px !important;
}
.vpd-input-group input {
  border-radius: 0;
  text-align: center;
}

.vpd-input-group label {
  margin-bottom: 0 !important;
}
.collapse .card-body{
  padding-left: 0.3rem !important;
  padding-right: 0.3rem !important;
}
@media (max-width: 767px) {
  [dir=rtl] #mainMapMob {
    width: 100vw;
    height: calc( 100% - 215px );
    direction: ltr !important;
    text-align: left !important;
    padding: 0 !important;
    margin: 0 !important;
    top: 0 !important;
    position: absolute;

    right: inherit  !important;
    left: 0 !important;
  }
  [dir="rtl"] #home .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    height: 70px !important;;
  }
}

.mobileOnMapHint button {
  height: 32px;
  width: 32px;
  padding: 0;
  font-size: 1.1rem;
  background: rgb(72, 128, 147) !important;
}

.mobileOnMapHistoryBtn {
  border-radius: 3px;
  padding: 0;
  opacity: 0.8;
  background: unset;
  right: 10px;
  bottom: 50px
}

.mobileOnMapHistoryBtn button {
  text-align: center;
  color: #eee;
  background: #5113db;
}

[dir] .btn-secondary:active{
  background-color: unset !important;
}
.vpd-wrapper {
  z-index: 999 !important;
}
.rounded-circle{
  color: #fff !important;
}

[dir="rtl"] .v-input--selection-controls__ripple{

}
.v-menu{
  display: block !important;
}

.v-menu__content {
  font-size: 12px !important;
  opacity: 1 !important;
  background: #fff !important;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}

.Vue-Toastification__container{
  z-index: 999;
}
</style>
