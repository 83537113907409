<template>
  <section
    id="profile"
    style="width: 100vw;
            padding: 0px !important;
            margin: 0px !important;
            top: 0px !important;
            left: 0px !important;"
  >

    <div
      class="card"
      style="top: -53px;margin: 0 10px;"
    >
      <b-card-body>
        <b>
          درباره نرم افزار</b>
        <br>
        <p>
          همه ما الگوهای ذهنی داریم که از رفته رفته در ما شکل گرفته، این الگو‌ها،معیارها و قالب‌هایی رو در ما ایجاد کردند که زندگی رو برامون راحت‌تر کرده و از طرفی هم خلاقیت‌مون رو به فنا داده!
          برای مثال ساختاری که از دوچرخه در ذهن ماست یک بدنه فلزی و دو چرخ دایره‌ای کامل هست، اما آیا امکان نداره دوچرخه بسازیم که بدنه بجای فلزی پارچه ای باشه؟ یا چرخ‌هاش نیم دایره ای باشه؟ یا اصلا چرخ نداشته باشه؟
          چرا واقعا امکان داره، اگر قالب بتونی ذهنیت رو بشکنی؛ من چند نمونه رو داخل پیج اینستاگرام گذاشتم که میتونی ببینی! حتما سر بزن!
          این نرم‌افزار فقط یه کار انجام میده!
          ذهن ما رو به چالش میکشه تا مثل یک فرد خلاق فکر کنیم و همه‌ی الگوهای ذهنیمون رو بشکنیم!
          این نرم‌افزار یک بانک ایده هست، کلی ایده توش هست که منتظر توست تا با ذهن خلاقت خلقش کنی! من تو پیج یادت میدم ایده رو چطوری پخته کنی،ثبت کنی و چطوری برای تولیدش اقدام کنی.
          <br>
          خالق ایده‌های ناب باشی. دوستت دارم 🌹♥️
          <b>امیر اسکویی</b>
        </p>
      </b-card-body>
    </div>
  </section>

</template>
<script>
import Vue from 'vue'
import {
  BCardBody,
} from 'bootstrap-vue'
import router from '@/router'
import { getUserData } from '@/auth/utils'
import ModelService from '@/services/model.service'

export default {
  components: {
    BCardBody,
  },
  data: () => ({
    usertype: getUserData().type,
    tab: null,
    selectedItem: -1,
    selectedItem2: -1,
    itemsUser: [
      { text: 'تمدید اشتراک', icon: 'mdi-account', rout: 'renewSubscription' },
      { text: 'صندوق پیام', icon: 'mdi-message-bulleted', rout: 'inbox' },
      { text: 'فروشگاه', icon: 'mdi-shopping', rout: 'shop' },
      { text: 'سوالات متداول', icon: 'mdi-apps', rout: 'faq' },
      { text: 'خروج از حساب کاربری', icon: 'mdi-logout', rout: 'logout' },
    ],
    itemsManager: [
      { text: 'مشتریان', icon: 'mdi-account', rout: 'admin-customer-list' },
      { text: 'نماینده ها', icon: 'mdi-message-bulleted', rout: 'admin-agent-list' },
      { text: 'دستگاه ها', icon: 'mdi-shopping', rout: 'admin-device-all' },
      { text: 'مدل ها', icon: 'mdi-apps', rout: 'admin-model-list' },
      { text: 'مدیران', icon: 'mdi-apps', rout: 'admin-manager-list' },
      { text: 'سرورها', icon: 'mdi-apps', rout: 'admin-server-list' },
    ],
  }),
  computed: {
  },
  created() {

  },
  mounted() {
  },
  mutations: {
    deleteItem(state, payload) {
      state.items.splice(payload, 1)
    },
  },
  methods: {
    logOut() {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('آیا مطمئن هستید؟')) {
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      }
    },
    tabClick(rout) {
      if (rout === 'logout') {
        if (confirm('آیا مطمئن هستید؟')) {
          this.$store.dispatch('auth/logout')
          this.$router.push('/login')
        }
      } else if (rout === 'renewSubscription' || rout === 'inbox' || rout === 'shop' || rout === 'faq') {
        const subscribeUrl = 'https://4-rah.com/%D8%AA%D9%85%D8%AF%DB%8C%D8%AF-%D8%AD%D8%B3%D8%A7%D8%A8-%DA%A9%D8%A7%D8%B1%D8%A8%D8%B1%DB%8C/'
        switch (rout) {
          case 'renewSubscription':
            if (Vue.prototype.$productionPlatform === 'ios') {
              window.location.href = `http://127.0.0.1:3232/QXNative/?action=launchBrowser-openUrl&url=${encodeURIComponent(subscribeUrl)}`
            } else {
              window.location.href = subscribeUrl
            }
            break
          default:
            if (Vue.prototype.$productionPlatform === 'ios') {
              window.location.href = 'http://127.0.0.1:3232/QXNative/?action=launchBrowser-openHomePage'
            } else {
              window.location.href = 'https://4-rah.com'
            }
        }
      } else {
        // eslint-disable-next-line no-unused-vars
        router.push({ name: rout }).catch(err => {})
      }
    },
    addClick() {
      router.push({ name: 'admin-device-add' })
    },
    onCancel() {
      this.show = false
      this.$router.push({ name: 'model-list' })
    },
  },
}
</script>

<style>
#profile {
  /*background-color: #232633;*/
  z-index: 9;
}
#profile .card, #profile .card-body {
  /*background-color: #232633;*/
  border-radius: 0;
}
#profile .theme--dark.v-list{
  /*background-color: #232633 !important;*/
}
[dir] #profile .theme--dark.v-tabs > .v-tabs-bar{
  /* background-color: #232633 !important;
   color: #fff;*/
}
.v-tab{
  letter-spacing: normal !important;
}
#profile .v-list-item{
  padding: 5px;
  box-shadow: 0 1px 0px rgb(253, 253, 253), 0 1px 0px rgba(0, 0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 7px;

}
#profile .v-list-item__icon{
  margin: 16px 10px;
}
#profile p{
  line-height: 2rem;
  text-align:justify
}
</style>
