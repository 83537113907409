<template>
  <section
      id="device-dashboard"
      style="width: 100vw;
            padding: 0px !important;
            margin: 0px !important;
            left: 0px !important;
            position: absolute;
z-index: 9;"
  >
  </section>
</template>
<script>
import PreLoaderSpinner from '@core/components/pre-loader-spinner/PreLoaderSpinner.vue'
import {
  BCard, BCardBody, BRow, BCol, BContainer, BFormCheckbox, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import router from '@/router'
import Device from '@/models/device'
import deviceService from '@/services/device.service'

// eslint-disable-next-line import/no-named-as-default
import Config from '../../../config'
import CustomerService from '@/services/customer.service'
import UserService from '@/services/user.service'
import { getUserData } from '@/auth/utils'



export default {
  components: {
    BFormInput,
    BFormGroup,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BContainer,
    BFormCheckbox,
    BButton,
    PreLoaderSpinner,
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  created() {
  },
  mutations: {

  },
  methods: {

  },
}
</script>
<style>
#device-dashboard {
  top: 162px !important;
  height: calc( 100vh - 162px ) !important;
  background: #d5d5d5;
}

</style>
