<template>
  <div
    id="preLoaderSpinnerArea"
    class=""
  >
    <div id="preLoaderSpinnerInside">
      <Spinner /></div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'

export default {
  directives: {
    Spinner,
  },
  components: {
    Spinner,
  },
  setup() {
    document.body.classList.add('preLoaderSpinnerNoOverflow')
  },
  methods: {
    hide() {
      const element = document.getElementById('preLoaderSpinnerArea')
      // برای کنترل v-if
      if (element) {
        element.classList.add('preLoaderSpinnerHidden')
        document.body.classList.remove('preLoaderSpinnerNoOverflow')
      }
    },
  },
}
</script>
<style>
.preLoaderSpinnerHidden {
  display: none;
}
.preLoaderSpinnerNoOverflow {
  overflow: hidden !important;
}
#preLoaderSpinnerArea {
  position: fixed;
  top: 0;
  left: 0;
  background: #242632;
  width: 100%;
  height: 100%;
  /* یکی قبل تر از فوتر که بر روی فوتر در موبایل قرار نگیرد */
  z-index: 99999998 !important;
}
#preLoaderSpinnerInside {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
</style>
