<template>
  <div data-app>

    <div
      v-show="footerSelected==='profile'"
      class="profile-home "
    >
      <slot name="profile">
        <app-profile />
      </slot>
    </div>
    <div
      v-show="footerSelected==='dashboard'"
      class="dashboard-home "
    >
      <slot name="dashboard">
        <app-dashboard
          ref="dashboardComponent"
        />
      </slot>
    </div>
    <div
      v-show="footerSelected==='home'"
      class="main-home "
    >
      <slot name="main">
        <app-main
          ref="homeComponent"
          @on-change-page="changePageFromMain"
        />
      </slot>
    </div>
    <footer
      v-show="footerSelected!=='deviceDetail' && footerSelected!=='playback' && footerSelected!=='tracking' && footerSelected!=='command'"
      class="footer footer-home footer-light d-xl-none d-lg-none d-md-none "
    >
      <slot name="footer">
        <app-footer
          ref="footer"
          @on-update-Footer-Select="updateFooterSelect"
        />
      </slot>
    </footer>
  </div>
</template>
<script>
import AppFooter from '@core/layouts/components/AppFooter.vue'
import Vue from 'vue'
import AppDashboard from './Home/Dashboard.vue'
import AppProfile from './Home/Profile.vue'
import AppMain from './Home/Main.vue'

export default {
  components: {
    AppDashboard,
    AppFooter,
    AppProfile,
    AppMain,
  },
  data() {
    return {
      deviceInfobottom: '-129px',
      footerSelected: 'home',
      isMobile: Vue.prototype.$functions.isMobile(),
    }
  },
  created() {

    const $this = this
    window.onpopstate = async () => {
      if ($this.footerSelected !== 'home') {
        $this.footerSelected = 'home'
        $this.addHashToLocation('')
        $this.$refs.footer.tabClick('home')
      } else if (Vue.prototype.$productionPlatform === 'android') {
        exitFromApp()
      }
    }
  },
  mounted() {
  },
  methods: {
    changePage(rout) {
      this.$refs.footer.tabClick(rout)
    },
    changePageFromMain(rout, imei) {
      if (rout === 'dashboard') {
        this.footerSelected = 'dashboard'
        this.addHashToLocation('dashboard')
        this.$refs.dashboardComponent.doStart()
      }
    },
    updateFooterSelect(v) {
      this.footerSelected = v
      if (v === 'profile') {
        this.addHashToLocation('profile')
      }
      if (v === 'dashboard') {
        this.addHashToLocation('dashboard')
      }
      if (v === 'home') {
        this.addHashToLocation('')
      }
    },
    addHashToLocation(params) {
      // eslint-disable-next-line no-param-reassign
      if (params !== '') params = `#!${encodeURIComponent(params)}`
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        null,
        `${this.$route.path}${params}`,
      )
    },
  },
}
</script>
<style>
.footer-fixed footer{
  z-index: 999 !important;
}
</style>
